<template>
  <transition name="fade" appear appear-active-class="fade-enter-active">
    <div class="files-library" style="min-height: 600px;">
      <v-progress-linear
          style="position: absolute"
          v-if="showProgress"
          :value="progressValue"
          color="light-blue" background-color="white" height="2"></v-progress-linear>
      <address-bar :path="path" :nav-parent="navParent" ref="addrBar"></address-bar>
      <div v-if="dataLoaded" class="files-list" style="width:100%; padding: 15px; min-height: 500px;"
           ref="filesListArea"
           @contextmenu="openContextMenu"
           @mousedown="startSelect($event)">
        <file-block
            v-for="(file, fileStat) in filesList"
            :file="file"
            :key="'st'+fileStat"
            :ref="'file'+fileStat"
            @openFileContextMenu="openFileContextMenu"
            @openFile="openFile"
            @deselectAll="deselectAllFiles"
        ></file-block>
        <add-button @startAddFile="startAddFile"></add-button>
        <v-overlay
            :absolute="false"
            :value="overlay"
        >
          <v-btn color="primary" style="position: fixed;top:50px;right:50px;width:130px" @click="overlay=false">
            Закрыть
          </v-btn>
          <v-btn color="primary" style="position: fixed;top:100px;right:50px;width:130px"
                 @click="downloadFie(fileToShow.id)">Скачать
          </v-btn>
          <div class="text-center">
            <v-progress-circular
                v-if="selectedFileExtension==='pdf' || selectedFileExtension==='txt' ||  selectedFileExtension==='doc' ||  selectedFileExtension==='docx' ||  selectedFileExtension==='ppt' ||  selectedFileExtension==='pptx' ||  selectedFileExtension==='xls' ||  selectedFileExtension==='xlsx'"
                indeterminate
                color="primary"
            ></v-progress-circular>
          </div>
          <iframe v-if="selectedFileExtension==='pdf' || selectedFileExtension==='txt'"
                  :src="'https://docs.google.com/viewer?url='+ getFilePublicUrl(fileToShow.id) + '&embedded=true'"
                  style="position: fixed; top: 0; height: 100%;width: 55%;left: 25%"></iframe>

          <iframe
              v-if="selectedFileExtension==='doc' || selectedFileExtension==='docx' || selectedFileExtension==='ppt' || selectedFileExtension==='pptx' || selectedFileExtension==='xls' || selectedFileExtension==='xlsx'"
              :src="'https://view.officeapps.live.com/op/embed.aspx?src='+getFilePublicUrl(fileToShow.id)"
              style="position: fixed; top: 0; height: 100%;width: 55%;left: 25%"></iframe>

          <video controls autoplay class="preplay-video"
                 v-if="selectedFileExtension==='mp4' || selectedFileExtension == 'avi'"
                 :src="getFilePublicUrl(fileToShow.id)"></video>

          <v-layout justify-center>
            <img
                class="preshow-img"
                v-if="selectedFileExtension==='bmp' || selectedFileExtension==='png' || selectedFileExtension==='gif' || selectedFileExtension==='jpg' || selectedFileExtension==='jpeg'"
                :src="getFilePublicUrl(fileToShow.id)"
            /></v-layout>
        </v-overlay>
        <div class="selectArea"
             ref="selectAreaEl"
             :style="{top: selAreaTop + 'px',
                        left: selAreaLeft + 'px',
                        width: selAreaWidth + 'px',
                        height: selAreaHeight + 'px'}"
             v-if="showSelectArea">
        </div>

        <v-menu
            v-model="showContextMenu"
            :position-x="contextMenuX"
            :position-y="contextMenuY"
            absolute
            offset-y
            transition="scale-transition"
        >
          <v-card
              class="mx-auto"
              max-width="300"
              tile
          >
            <v-list dense v-model="contextMenuSelectedItem">
              <v-list-item-group
                  color="white"
                  mandatory
              >
                <v-list-item
                    v-for="(item, index) in contextMenuItems"
                    :key="index"
                    @click="menuItemClicked(item.action)"
                >
                  <v-list-item-icon>
                    <v-icon dense color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>{{ item.title }}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <input type="file" multiple="multiple" style="display: none" ref="fileInput" @change="handleUpload($event)">
        <thumbnail-generator ref="thumbailGenerator"></thumbnail-generator>

        <v-dialog
            v-model="createDirectoryPopup"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Создать папку</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="createDirForm">
                  <v-text-field outlined lazy-validation :rules="nameRules" label="Название папки"
                                v-model="newName"></v-text-field>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="gray"
                  text
                  @click="createDirectoryPopup = false"
              >
                Отмена
              </v-btn>
              <v-btn
                  color="primary"
                  text
                  @click="createDirectory()"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
            v-model="renamePopup"
            persistent
            max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Переименовать файл или папку</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="createDirForm">
                  <v-text-field outlined lazy-validation :rules="nameRules" label="Новое название"
                                v-model="newName"></v-text-field>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="gray"
                  text
                  @click="renamePopup = false"
              >
                Отмена
              </v-btn>
              <v-btn
                  color="primary"
                  text
                  @click="rename()"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </transition>
</template>

<script>
import AddressBar from "@/components/files/AddressBar";
import FileBlock from "@/components/files/FileBlock";
import AddButton from "@/components/files/AddButton";
import ThumbnailGenerator from "@/components/files/ThumbnailGenerator";
import api from "@/modules/api";

export default {
  name: 'FileStorage',
  props: ['containerId', 'navParent'],
  components: {ThumbnailGenerator, AddButton, FileBlock, AddressBar},
  data() {
    return {
      dataLoaded: false,
      apiError: false,
      path: '',
      filesList: [],
      showProgress: false,
      progressValue: 0,
      showContextMenu: false,
      contextMenuX: 0,
      contextMenuY: 0,
      contextMenuItems: [],
      showSelectArea: false,
      selectStartPosX: null,
      selectStartPosY: null,
      selectEndPosX: null,
      selectEndPosY: null,
      overlay: false,
      fileToShow: null,
      selectedFileExtension: null,
      createDirectoryPopup: false,
      menuPresetFilesArea: [
        {title: 'Создать папку', icon: 'mdi-folder', action: 'showCreateDirectoryDialog'}
      ],
      menuPresetOneFile: [
        {title: 'Открыть', icon: 'mdi-book-open-page-variant', action: 'open'},
        {title: 'Скачать', icon: 'mdi-download', action: 'download'},
        {title: 'Переименовать', icon: 'mdi-form-textbox', action: 'rename'},
        {title: 'Удалить', icon: 'mdi-delete', action: 'delete'}
      ],
      menuPresetMultiple: [
        {title: 'Удалить', icon: 'mdi-delete', action: 'delete'}
      ],
      menuPresetFolder: [
        {title: 'Открыть', icon: 'mdi-book-open-page-variant', action: 'open'},
        {title: 'Переименовать', icon: 'mdi-form-textbox', action: 'rename'},
        {title: 'Удалить', icon: 'mdi-delete', action: 'delete'}
      ],
      canBePreviewedExtensions: ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt', 'mp4', 'avi', 'bmp', 'png', 'jpg', 'jpeg', 'gif'],
      hasVideoThumbnailExtensions: ['mp4'],
      hasImageThumbnailExtensions: ['bmp', 'png', 'jpg', 'jpeg', 'gif'],
      contextMenuSelectedItem: null,
      newName: '',
      renamePopup: false,
      nameRules: [
        v => !!v || 'Заполните поле',
        v => (!!v && v.length < 128) || 'Слишком длинное название',
        v => !(/[\\/`<>?*:|]/).test(v) || 'Недопустимые символы',
        v => !(v === '.' || v === '..') || 'Недопустимое название'
      ]
    }
  },
  computed: {
    selAreaLeft() {
      return Math.max(Math.min(this.selectStartPosX, this.selectEndPosX), this.$refs.filesListArea.getBoundingClientRect().left)
    },
    selAreaTop() {
      return Math.max(Math.min(this.selectStartPosY, this.selectEndPosY), this.$refs.filesListArea.getBoundingClientRect().top)
    },
    selAreaWidth() {
      let r = this.$refs.filesListArea.getBoundingClientRect()
      return Math.abs(Math.min(Math.max(this.selectEndPosX, r.left), r.right) - this.selectStartPosX)
    },
    selAreaHeight() {
      let r = this.$refs.filesListArea.getBoundingClientRect()
      return Math.abs(Math.min(Math.max(this.selectEndPosY, r.top), r.bottom) - this.selectStartPosY)
    }
  },
  methods: {
    startSelect(e) {
      if (e.button !== 0 || this.overlay) {
        return
      }
      this.showContextMenu = false
      this.selectEndPosX = this.selectStartPosX = e.clientX - 3
      this.selectEndPosY = this.selectStartPosY = e.clientY
      this.showSelectArea = true
      this.$nextTick(function () {
        this.selectFiles()
      })
    },
    updateSelectArea(e) {
      if (this.showSelectArea) {
        this.selectEndPosX = e.clientX
        this.selectEndPosY = e.clientY
        this.selectFiles()
        e.preventDefault()
      }
    },
    stopSelect() {
      this.showSelectArea = false
    },
    deselectAllFiles() {
      for (let i = 0; i < this.filesList.length; i++) {
        let fileComponent = this.$refs['file' + i][0]
        fileComponent.deselect()
      }
    },
    selectFiles() {
      let selectRect = this.$refs.selectAreaEl.getBoundingClientRect()
      for (let i = 0; i < this.filesList.length; i++) {
        let fileComponent = this.$refs['file' + i][0]
        if (this.rectsIntersect(selectRect, fileComponent.getRect())) {
          fileComponent.select()
        } else {
          fileComponent.deselect()
        }
      }
    },
    rectsIntersect(rect1, rect2) {
      return rect1.right >= rect2.left && rect1.left <= rect2.right && rect1.top <= rect2.bottom && rect1.bottom >= rect2.top
    },
    openContextMenu(e) {
      if (this.overlay) {
        return
      }
      this.contextMenuItems = this.menuPresetFilesArea
      this.deselectAllFiles()
      e.preventDefault()
      this.showMenu(e)
    },
    openFileContextMenu(e) {
      if (this.overlay) {
        return
      }
      let sels = this.getSelectedFiles()
      if (sels.length === 1 && !sels[0].directory) {
        this.contextMenuItems = this.menuPresetOneFile
      } else if (sels.length === 1) {
        this.contextMenuItems = this.menuPresetFolder
      } else {
        this.contextMenuItems = this.menuPresetMultiple
      }
      e.preventDefault()
      e.stopPropagation()
      this.showMenu(e)
    },
    showMenu(e) {
      this.showContextMenu = false
      this.contextMenuX = e.clientX
      this.contextMenuY = e.clientY
      this.$nextTick(() => {
        this.showContextMenu = true
      })
    },
    async updateFiles () {
      this.showProgress = true
      this.progressValue = 25
      this.apiError = false
      let req = await api.get('storage/getFilesList', {containerId: this.containerId, path: this.path})
      if (req.ok) {
        this.filesList = req.payload.sort(this.compareFiles)
        this.dataLoaded = true
        this.progressValue = 75
        if(this.filesListContainsThumbnailExtensions()) {
          this.updateThumbnails()
        } else {
          setTimeout(() => { this.hideProgress() },200);
        }
      } else {
        this.apiError = true
      }
    },
    filesListContainsThumbnailExtensions() {
      for (let file of this.filesList) {
        let ext = this.extractFileExtFromPath(file.path)
        if (this.hasImageThumbnailExtensions.includes(ext) || this.hasVideoThumbnailExtensions.includes(ext)) {
          return true
        }
      }
      return false
    },
    compareFiles(file1, file2) {
      if (file1.directory && !file2.directory) {
        return -1
      } else if (!file1.directory && file2.directory) {
        return 1
      }
      return file1.path.localeCompare(file2.path)
    },
    async updateThumbnails() {
      let req = await api.get('/storage/getThumbnails', {containerId: this.containerId, path: this.path})
      if (req.ok) {
        for (let i = 0; i < this.filesList.length; i++) {
          let fileComponent = this.$refs['file' + i][0]
          let f = fileComponent.file
          for (let t of req.payload) {
            if (f.id === t.storedFile.id) {
              f.thumbnail = t.fileThumbnail
              fileComponent.$forceUpdate()
              break
            }
          }
        }
        setTimeout(() => {
          this.hideProgress()
        }, 200);
      }
      this.progressValue = 100
      setTimeout(async () => {
        this.showProgress = false
        await this.$forceUpdate()
        this.progressValue = 0
        this.showProgress = true
      }, 200);
    },
    async hideProgress() {
      this.showProgress = false
      await this.$forceUpdate()
      this.progressValue = 0
      this.showProgress = true
    },
    startAddFile() {
      this.$refs.fileInput.click()
    },
    async handleUpload(e) {
      let files = e.target.files
      if (files.length > 0) {
        let res = true
        let errorMessage = ''
        for (let file of this.askIfReplaceExisting(files)) { // Убираем из списка файлы, которые не нужно заменять, итерируем по полученному списку
          let resp = await this.uploadFile(file)
          if (resp.status !== 'OK') {
            res = false
            errorMessage = resp.errorMessage
          } else if (!file.replaceExisting) { // Файл загрузился на сервер, покажем его в области файлов в браузере
            resp.storedFile.selected = true
            this.filesList.push(resp.storedFile)
          } else { // файл был загружен, но с таким названием уже был; нужно выделить загруженный файл в области файлов
            // this._setSelectionStatusOfFileWithName(file.name, true);
          }
        }
        if (!res) {
          alert('Ошибка загрузки одного или нескольких файлов: ' + errorMessage)
        }
      }
    },
    askIfReplaceExisting(files) {
      let resultFiles = []
      for (const file of [...files]) {
        let fileExists = false // Существует ли уже этот файл? Далее будет проверено
        let replaceExisting = true // Если да, то заменить ли. По умолчанию - да.
        for (let i = 0; i < this.filesList.length; i++) {
          if (this.extractFileNameFromPath(this.filesList[i].path) === file.name) {
            fileExists = true
            if (!window.confirm('Файл "' + file.name + '" существует. Заменить?')) {
              replaceExisting = false
            }
            break
          }
        }
        if (fileExists && replaceExisting) {
          file.replaceExisting = true
        }
        if (!fileExists || replaceExisting) {
          resultFiles.push(file)
        }
      }
      return resultFiles
    },
    extractFileNameFromPath(path) {
      return path.substring(path.lastIndexOf('/') + 1)
    },
    extractFileExtFromPath(path) {
      if (path.includes('.')) {
        return path.substring(path.lastIndexOf('.') + 1).toLowerCase()
      } else {
        return ''
      }
    },
    async uploadFile(file) {
      let thumbnail = null
      let ext = this.extractFileExtFromPath(file.name)
      if (this.hasVideoThumbnailExtensions.includes(ext)) {
        thumbnail = await this.$refs.thumbailGenerator.generateVideoThumbnail(file)
      } else if (this.hasImageThumbnailExtensions.includes(ext)) {
        thumbnail = await this.$refs.thumbailGenerator.generateImageThumbnail(file)
      }
      let formData = new FormData()
      formData.append('file', file)
      formData.append('containerId', this.containerId)
      formData.append('path', this.path)
      if (thumbnail) {
        formData.append('fileThumbnail', thumbnail)
      }
      let req = await api.postFormData('/storage/uploadFile', formData)
      if (req.ok) {
        let storedFile = req.payload
        storedFile.thumbnail = thumbnail
        return {status: 'OK', storedFile: storedFile}
      } else {
        return {status: 'ERROR', storedFile: null, errorMessage: req.message}
      }
    },
    downloadfile(buf, name){
      //const buf = new Uint8Array(file.size)
      //buf.set(chunk, i*1024)
      const f = new File([buf], name);
      const url = URL.createObjectURL(f)
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      //a.click();
      window.URL.revokeObjectURL(url);
    },
    openFile(file) {
      let ext = this.extractFileExtFromPath(file.path)
      if (file.directory) {
        this.$router.push({path: this.navParent + file.path})
      } else if (this.canBePreviewedExtensions.includes(ext)) {
        this.fileToShow = file
        this.selectedFileExtension = ext
        this.overlay = true
      } else {
        this.downloadFie(file.id)
      }
    },
    getSelectedFiles() {
      let res = []
      for (let file of this.filesList) {
        if (file.selected) {
          res.push(file)
        }
      }
      return res
    },
    concatPaths(path1, path2) {
      if (!path1.endsWith('/')) {
        path1 = path1 + '/'
      }
      return path1 + path2
    },
    getFilePublicUrl(fileId) {
      let c = Math.abs(Math.sin(fileId * this.containerId) * 1000)
      c = Math.floor((c - Math.floor(c)) * 102922323)
      return api.backendUrl + '/storage/public/file/' + this.containerId + '/' + fileId + '/' + c
    },
    downloadFie(fileId) {
      let url = this.getFilePublicUrl(fileId)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    },
    menuItemClicked(action) {
      switch (action) {
        case 'showCreateDirectoryDialog':
          this.showCreateDirectoryDialog()
          break
        case 'open':
          this.openFile(this.getSelectedFiles()[0])
          break
        case 'download':
          this.downloadFie(this.getSelectedFiles()[0].id)
          break
        case 'rename':
          this.newName = this.extractFileNameFromPath(this.getSelectedFiles()[0].path)
          this.renamePopup = true
          break
        case 'delete':
          this.deleteSelectedFiles()
          break
        case '':
      }
    },
    showCreateDirectoryDialog() {
      this.newName = ''
      this.createDirectoryPopup = true
    },
    async createDirectory () {
      if (!this.$refs.createDirForm.validate()) {
        return
      }
      let formData = new FormData()
      formData.append('containerId', this.containerId)
      formData.append('path', this.concatPaths(this.path, this.newName))
      let req = await api.postFormData('/storage/createDirectory', formData)
      if (req.ok) {
        let sf = req.payload
        sf.selected = true
        this.filesList.push(sf)
      } else {
        alert('Не удалось создать папку!')
      }
      this.createDirectoryPopup = false
    },
    async rename() {
      let file = this.getSelectedFiles()[0]
      if (this.extractFileNameFromPath(file.path) === this.newName) {
        return
      }
      let formData = new FormData()
      formData.append('containerId', this.containerId)
      formData.append('oldPath', file.path)
      formData.append('newName', this.newName)
      let req = await api.postFormData('/storage/renameFile', formData)
      if (req.ok) {
        let sf = req.payload
        file.path = sf.path
      } else {
        alert('Не удалось переименовать файл или папку!')
      }
      this.renamePopup = false
    },
    async deleteSelectedFiles() {
      let files = this.getSelectedFiles()
      for (let file of files) {
        if (!file.editable) {
          alert('Не допускается удаление ' + (file.directory ? 'папки ' : 'файла ') + this.extractFileNameFromPath(files[0].path) + '!')
          return
        }
      }
      let msg = ''
      if (files.length === 1) {
        msg = 'Вы действительно хотите удалить ' + (files[0].directory ? 'папку ' : 'файл ') + this.extractFileNameFromPath(files[0].path) + '?'
      } else {
        msg = 'Вы действительно хотите удалить ' + files.length + ' файлов (папок)?'
      }
      if (!window.confirm(msg)) {
        return
      }
      for (let file of files) {
        let res = await this.deleteFile(file)
        if (!res) {
          alert('Не удалось удалить файл!')
          break
        }
      }
    },
    async deleteFile(file) {
      let formData = new FormData()
      formData.append('containerId', this.containerId)
      formData.append('path', file.path)
      let req = await api.postFormData('/storage/deleteFile', formData)
      if (req.ok) {
        this.removeFileFromList(file)
        return true
      } else {
        return false
      }
    },
    removeFileFromList(delFile) {
      for (let i = 0; i < this.filesList.length; i++) {
        if (delFile.id === this.filesList[i].id) {
          this.filesList.splice(i, 1)
        }
      }
    }
  },
  beforeMount() {
    window.addEventListener('mousemove', this.updateSelectArea)
    window.addEventListener('mouseup', this.stopSelect)
    this.path = this.$route.params.pathMatch
    this.updateFiles()
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.updateSelectArea)
    window.removeEventListener('mouseup', this.stopSelect)
  },
  async beforeRouteUpdate(to, from, next) {
    this.path = to.params.pathMatch
    this.apiError = false
    //this.filesList = []
    this.showContextMenu = false
    await this.updateFiles()
    this.$refs.addrBar.$forceUpdate()
    next()
  }
}
</script>

<style scoped>
.files-list {
}

.selectArea {
  background: rgba(66, 113, 215, 0.3);
  border: 1px solid rgba(52, 84, 246, 0.5);
  position: fixed;
  z-index: 100;
}

.preplay-video {
  width: 55%;
  left: 25%;
  top: 10%;
  position: fixed;
}

.preshow-img {
  max-width: 55%;
  max-height: 60%;
  top: 10%;
}

.v-list-item__icon {
  margin-right: 10px !important;
}
</style>
